<template>
  <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink> {{ account.displayName }} </CHeaderNavLink>
    </template>
    <CDropdownItem @click="password()"> <CIcon name="cil-lock-locked" /> 修改密碼 </CDropdownItem>
    <CDropdownItem @click="logout()"> <CIcon name="cil-user" /> 登出 </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      account: {
        gid: null,
        username: "",
        displayName: "",
      },
    };
  },
  methods: {
    password() {
      this.$router.push({ name: "修改密碼" });
    },
    logout() {
      localStorage.removeItem("authorization");
      this.$router.push({ name: "登入" }, () => this.$nextTick(() => this.$notify({ type: "success", text: "登出成功" })));
    },
    getAccount() {
      this.$http.get("/account").then((response) => {
        if (response.data.success) {
          this.account.gid = response.data.result.gid;
          this.account.username = response.data.result.username;
          this.account.displayName = response.data.result.displayName;
        } else {
          localStorage.removeItem("authorization");
          this.$router.push({ name: "登入" }, () => this.$nextTick(() => this.$notify({ type: "error", text: "請先登入系統" })));
        }
      });
    },
  },
  mounted() {
    this.getAccount();
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>