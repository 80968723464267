export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: '首頁',
        to: '/dashboard',
        fontIcon: 'fas fa-chart-line fa-fw',
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['主要功能']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: '預約系統',
        route: '/reserve',
        icon: 'cil-description',
        items: [
          {
            name: '預約說明',
            to: '/reserve/description'
          },
          {
            name: '預約紀錄',
            to: '/reserve/record'
          },
          {
            name: '預約設定',
            to: '/reserve/setting'
          }
        ]
      },{
        _name: 'CSidebarNavDropdown',
        name: '預約系統',
        route: '/line',
        icon: 'cil-description',
        items: [
          {
            name: '報價說明',
            to: '/quote/description'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: '服務內容',
        route: '/service',
        icon: 'cil-description',
        items: [
          {
            name: '服務設定',
            to: '/service/service'
          },
          {
            name: '區域設定',
            to: '/service/county'
          },
          {
            name: '項目設定',
            to: '/service/status'
          }
        ]
      }
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['系統相關']
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: '系統設定',
      //   to: '/setting',
      //   icon: 'cil-drop'
      // },
    ]
  }
]